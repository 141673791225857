import React from 'react';
import { Pane, Heading, Image } from 'evergreen-ui';

const Header: React.FC = () => {
  return (
    <Pane backgroundColor="#dddedb" padding={16} display="flex" alignItems="center">
      <Image src="/oysterLogo.webp" alt="Oyster Logo" height={40} />
      <Heading size={600} marginLeft={16}>Oyster</Heading>
    </Pane>
  );
};

export default Header;

import React, { useEffect, useState } from 'react';
import {Table, Pane, IconButton, Badge, Button, Tooltip, Text, Strong, Dialog} from 'evergreen-ui';
import { oLock } from '../../models/LockModel';
import { lock, unlock, ping, getLockState } from '../../api/lockApi/lockApi';

type LockTableProps = {
    locks: oLock[];
    refreshAllLocks: () => void;
};

const LockIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
        <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
    </svg>
);

const UnlockIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
        <path d="M7 11V7a5 5 0 0 1 9.9-1"></path>
    </svg>
);

const RefreshIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M3 2v6h6"></path>
        <path d="M21 12A9 9 0 0 0 6 5.3L3 8"></path>
        <path d="M21 22v-6h-6"></path>
        <path d="M3 12a9 9 0 0 0 15 6.7l3-2.7"></path>
    </svg>
);

const LockTable: React.FC<LockTableProps> = ({ locks: initialLocks, refreshAllLocks }) => {
    const [locks, setLocks] = useState<oLock[]>(initialLocks);
    const [selectedLock, setSelectedLock] = useState<oLock | null>(null);

    useEffect(() => {
        setLocks(initialLocks);
    }, [initialLocks]);

    useEffect(() => {
        const interval = setInterval(refreshAllLocks, 30000);
        return () => clearInterval(interval);
    }, [refreshAllLocks]);

    const updateLock = async (id: string, action: 'lock' | 'unlock' | 'ping') => {
        try {
            if (action === 'lock') await lock(id);
            else if (action === 'unlock') await unlock(id);
            else if (action === 'ping') await ping(id);

            await new Promise(resolve => setTimeout(resolve, 6000));
            const updatedLock = await getLockState(id);
            setLocks(prevLocks => prevLocks.map(lock => lock.id === id ? updatedLock : lock));
        } catch (error) {
            console.error(`Failed to ${action} lock:`, error);
        }
    };

    const handleLock = (id: string) => updateLock(id, 'lock');
    const handleUnlock = (id: string) => updateLock(id, 'unlock');
    const handlePing = (id: string) => updateLock(id, 'ping');

    const shortenId = (id: string) => id.length < 8 ? id : `${id.slice(0, 4)}...${id.slice(-4)}`;

    const LockDetailsDialog = ({ isShown, lock, onClose }: { isShown: boolean, lock: oLock | null, onClose: () => void }) => (
        <Dialog
            isShown={isShown}
            title="Lock Details"
            onCloseComplete={onClose}
            confirmLabel="Close"
            hasCancel={false}
        >
            <Pane>
                <Text><Strong>ID:</Strong> {lock?.id}</Text><br/>
                <Text><Strong>Address:</Strong> {lock?.addr}</Text><br/>
                <Text><Strong>Status:</Strong> {lock?.is_online ? "Online" : "Offline"}</Text><br/>
                <Text><Strong>Lock State:</Strong> {lock?.state === 1 ? "Locked" : "Unlocked"}</Text><br/>
                <Text><Strong>Last Heartbeat: </Strong>
                     {(lock?.last_heartbeat)} second{(lock?.last_heartbeat === 1 ? "" : "s")} ago
                </Text><br/>
                <Text><Strong>Expected State:</Strong> {lock?.expected_state === 1 ? "Locked" : "Unlocked"}</Text>
            </Pane>
        </Dialog>
    );

    return (
        <Pane>
            <Button onClick={refreshAllLocks} marginBottom={16} iconBefore={RefreshIcon}>
                Refresh All Locks
            </Button>
            <Table>
                <Table.Head>
                    <Table.TextHeaderCell>UID</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Status</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Lock State</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
                </Table.Head>
                <Table.Body>
                    {locks.map(lock => (
                        <Table.Row key={lock.id} isSelectable onSelect={() => setSelectedLock(lock)}>
                            <Table.TextCell>{shortenId(lock.id)}</Table.TextCell>
                            <Table.Cell>
                                <Badge color={lock.is_online ? "green" : "red"}>
                                    {lock.is_online ? "Online" : "Offline"}
                                </Badge>
                            </Table.Cell>
                            <Table.Cell>
                                <Badge color={lock.state === 1 ? "blue" : "neutral"}>
                                    {lock.state === 1 ? "Locked" : "Unlocked"}
                                </Badge>
                            </Table.Cell>
                            <Table.Cell>
                                <Tooltip content={lock.state === 1 ? "Unlock" : "Lock"}>
                                    <IconButton
                                        icon={lock.state === 1 ? UnlockIcon : LockIcon}
                                        onClick={() => lock.state === 1 ? handleUnlock(lock.id) : handleLock(lock.id)}
                                        marginRight={8}
                                    />
                                </Tooltip>
                                <Tooltip content="Ping">
                                    <IconButton
                                        icon={RefreshIcon}
                                        onClick={() => handlePing(lock.id)}
                                    />
                                </Tooltip>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <LockDetailsDialog
                isShown={!!selectedLock}
                lock={selectedLock}
                onClose={() => setSelectedLock(null)}
            />
        </Pane>
    );
};

export default LockTable;

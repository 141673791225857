import axios from 'axios';
import { API_BASE_URL } from '../api_stat';
import { oLock } from '../../models/LockModel';

const api = axios.create({
  baseURL: API_BASE_URL,
});

export const getList = async (): Promise<oLock[]> => {
  try {
    const response = await api.get<oLock[]>('locks');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch locks:', error);
    throw new Error('Failed to fetch locks');
  }
};

export const lock = async (id: string): Promise<void> => {
  await api.post(`lock/${id}/lock`);
};

export const unlock = async (id: string): Promise<void> => {
  await api.post(`lock/${id}/unlock`);
};

export const ping = async (id: string): Promise<void> => {
  await api.get(`lock/${id}/ping`);
};

export const getLockState = async (id: string): Promise<oLock> => {
  try {
    const response = await api.get<oLock>(`lock/${id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to get lock state:', error);
    throw new Error('Failed to get lock state');
  }
};
import { useEffect, useState } from 'react';
import { Pane } from 'evergreen-ui';
import { oLock } from './models/LockModel';
import ErrorPage from './pages/ErrorPage';
import { CircularProgress } from '@mui/material';
import { getList } from './api/lockApi/lockApi';
import Header from './components/Header/Header';
import LockTable from './components/LockTable/LockTable';

function App() {
  const [locks, setLocks] = useState<oLock[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchLocks = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await getList();
      setLocks(data);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLocks();
  }, []);

  return (
    <Pane display="flex" flexDirection="column" height="100vh" background="#f5f5f5">
      <Header />
      <Pane display="flex" alignItems="center" justifyContent="center" flex="1">
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <ErrorPage message={error} />
        ) : (
          <LockTable locks={locks} refreshAllLocks={fetchLocks} />
        )}
      </Pane>
    </Pane>
  );
}

export default App;
import React from 'react';
import { Pane, Card, Text } from 'evergreen-ui';

type ErrorPageProps = {
  message?: string;
};

const ErrorPage: React.FC<ErrorPageProps> = ({ message }) => {
  return (
    <Pane
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Card
        elevation={2}
        background="white"
        padding={16}
        width={400}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Text size={600} marginBottom={16}>
          Something went wrong
        </Text>
        {message && (
          <Pane
            padding={8}
            backgroundColor="#ffe6e6" // pale red
            borderRadius={4}
            width="100%"
          >
            <Text color="red" size={500}>
              {message}
            </Text>
          </Pane>
        )}
      </Card>
    </Pane>
  );
};

export default ErrorPage;
